import React from 'react'

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image
} from '@react-pdf/renderer'

const widthColumn = 93
const widthColumnMM = `${widthColumn}mm`
const padding = 10
const paddingMM = `${padding}mm`

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: 'white',
    color: '#434343'
    //  fontFamily: 'Poppins'
  },
  sectionHeader: {
    flexDirection: 'row',
    height: '30mm',
    backgroundColor: '#f07204',
    width: '100%',
    paddingHorizontal: paddingMM,
    paddingVertical: '1mm',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  logo: { height: '60%' },
  textHeader: { color: 'white', fontSize: 14, fontFamily: 'PoppinsBold' },
  sectionBody: {
    flexDirection: 'row',
    paddingHorizontal: paddingMM,
    paddingVertical: paddingMM
  },
  sectionLeft: {
    marginRight: '4mm',
    width: widthColumnMM
  },
  sectionRight: {
    width: widthColumnMM
  },
  sectionTexto: {
    width: widthColumnMM,
    height: '12mm',
    position: 'absolute',
    top: '81mm',
    left: 0,
    backgroundColor: '#434343',
    opacity: 0.75,
    borderTopRightRadius: '5cm',
    borderTopLeftRadius: '5cm',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'PoppinsBold'
  },
  image: {
    width: widthColumnMM,
    height: widthColumnMM,
    position: 'absolute',
    top: 0,
    left: 0
  },
  textoTitle: {
    opacity: 1,
    color: 'white',
    fontWeight: 500
  },
  imageIcon: {
    borderTopLeftRadius: '5cm',
    borderTopRightRadius: '5cm',
    borderBottomLeftRadius: '5cm',
    borderBottomRightRadius: '5cm',
    width: '25mm',
    height: '25mm'
  },
  titleAcabado: {
    fontFamily: 'PoppinsBold',
    fontSize: 12,
    textAlign: 'center'
  },
  titleOpcion: {
    fontFamily: 'Poppins',
    fontSize: 10,
    textAlign: 'center'
  },
  precio: {
    fontFamily: 'Poppins',
    fontSize: 10,
    textAlign: 'center',
    height: '2mm'
  },
  sectionRowsAcabados: {
    position: 'absolute',
    top: `${widthColumn + padding}mm`,
    left: 0,
    width: `${widthColumn + 2}mm`,
    height: '150mm'
  },
  sectionRowsAcabadosBorder: {
    borderRightColor: '#f07204',
    borderRightWidth: '1pt'
  },
  sectionRowsAcabadosInner: {
    width: widthColumnMM,
    heigth: '100%'
  },
  sectionRowAcabado: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%'
  },
  sectionAcabado: {
    height: '50mm'
  },
  sectionAcabado1: {
    width: '93mm'
  },
  sectionAcabado2: {
    width: '46mm'
  },
  sectionAcabado3: {
    width: '31mm'
  },
  imageIcon1: {
    marginLeft: '34mm'
  },
  imageIcon2: {
    marginLeft: '11mm'
  },
  imageIcon3: {
    marginLeft: '3mm'
  },
  textTitles: {
    height: '19mm'
  },
  sectionEstanciaLista: {
    position: 'absolute',
    left: paddingMM,
    height: '53mm',
    width: '190mm'
  }
})

const AcabadoPdf = ({ acabado, num }) => {
  const numSection = styles[`sectionAcabado${num}`]
  const numIcon = styles[`imageIcon${num}`]

  return (
    <View style={{ ...styles.sectionAcabado, ...numSection }}>
      <View style={styles.textTitles}>
        <Text style={styles.titleAcabado}>{acabado.title.toUpperCase()}</Text>
        <Text style={styles.titleOpcion}>{acabado.opciones[0].title}</Text>
      </View>

      <Image
        style={{ ...styles.imageIcon, ...numIcon }}
        src={acabado.opciones[0].srcIcono}
      />
      <View style={styles.precio}>
        {acabado.opciones[0].precio !== '' ? (
          <Text>{acabado.opciones[0].precio}€</Text>
        ) : null}
      </View>
    </View>
  )
}

const RowAcabadoPdf = ({ rowAcabado }) => {
  return (
    <View style={styles.sectionRowAcabado}>
      {rowAcabado.map((acabado, index) => (
        <AcabadoPdf key={index} acabado={acabado} num={rowAcabado.length} />
      ))}
    </View>
  )
}

const EstanciaPdf = ({ estancia, position }) => {
  const images = [estancia.src]

  estancia.acabados.forEach(acabado => {
    acabado.opciones.forEach(opcion => {
      if (opcion.src !== '') {
        images.push(opcion.src)
      }
    })
  })

  const rowsAcabados = []
  let rowAcabado = []

  const addRowsAcabados = acabado => {
    if (rowAcabado.length === estancia.pdfIconosLinea) {
      rowsAcabados.push(rowAcabado)
      rowAcabado = []
    }

    rowAcabado.push(acabado)
  }

  const acabadoMultipleIcon = (acabado, index) => {
    const ac = { ...acabado }

    ac.opciones = [{ ...acabado.opciones[0] }]
    ac.opciones[0].srcIcono = acabado.opciones[0].srcIconoPdf[index]

    if (index !== 1) {
      ac.title = ''
      ac.opciones[0].title = ''
      ac.opciones[0].precio = ''
    }

    return ac
  }

  estancia.acabados.forEach(acabado => {
    if (acabado.opciones.length === 1) {
      if (acabado.opciones[0].srcIconoPdf.length === estancia.pdfIconosLinea) {
        if (rowAcabado.length > 0) {
          rowsAcabados.push(rowAcabado)
          rowAcabado = []
        }

        rowAcabado.push(acabadoMultipleIcon(acabado, 0))
        rowAcabado.push(acabadoMultipleIcon(acabado, 1))
        rowAcabado.push(acabadoMultipleIcon(acabado, 2))
      } else {
        addRowsAcabados(acabado)
      }
    } else {
      for (let i = 0, iLen = acabado.opciones.length; i < iLen; i++) {
        const ac = { ...acabado }
        ac.opciones = [acabado.opciones[i]]
        addRowsAcabados(acabado)
      }
    }
  })

  rowsAcabados.push(rowAcabado)

  const border = position === 'left' ? styles.sectionRowsAcabadosBorder : {}

  return (
    <View
      style={position === 'left' ? styles.sectionLeft : styles.sectionRight}
    >
      <View style={{ position: 'relative' }}>
        {images.map((imageSrc, index) => (
          <Image key={index} style={styles.image} src={imageSrc} />
        ))}
        <View style={styles.sectionTexto}>
          <Text style={styles.textoTitle}>{estancia.title.toUpperCase()}</Text>
        </View>
      </View>
      <View style={{ ...styles.sectionRowsAcabados, ...border }}>
        <View style={styles.sectionRowsAcabadosInner}>
          {rowsAcabados.map((rowAcabado, index) => (
            <RowAcabadoPdf key={index} rowAcabado={rowAcabado} />
          ))}
        </View>
      </View>
    </View>
  )
}

const PageTemplate = ({ children, srcLogo, textoHeaderPdf }) => {
  return (
    <Page size="A4" style={styles.page}>
      <View style={styles.sectionHeader}>
        {srcLogo !== '' ? <Image style={styles.logo} src={srcLogo} /> : null}
        <Text style={styles.textHeader}>{textoHeaderPdf}</Text>
      </View>
      <View style={styles.sectionBody}>{children}</View>
    </Page>
  )
}

const AcabadoListaPrecios = ({ acabado, index }) => {
  return (
    <View
      style={{
        position: 'absolute',
        right: 0,
        bottom: `${6 * index}mm`
      }}
    >
      <Text style={{ textAlign: 'right' }}>{acabado.opciones[0].precio}€</Text>
    </View>
  )
}

const AcabadoLista = ({ acabado, index }) => {
  return (
    <View
      style={{
        position: 'absolute',
        right: '50mm',
        bottom: `${6 * index}mm`
      }}
    >
      <Text style={{ textAlign: 'right' }}>{acabado.title}</Text>
    </View>
  )
}

const EstanciaLista = ({ estancia, index }) => {
  const ac = []

  estancia.acabados.forEach(acabado => {
    if (acabado.opciones.length > 0) {
      acabado.opciones.forEach(opcion => {
        const ac1 = { ...acabado }
        ac1.opciones = [opcion]

        ac.push(ac1)
      })
    }
  })

  return (
    <View style={{ ...styles.sectionEstanciaLista, top: `${53 * index}mm` }}>
      <View
        style={{
          position: 'absolute',
          fontFamily: 'Poppins',
          fontSize: 12,
          right: 0,
          bottom: '9mm',
          backgroundColor: 'red',
          textAlign: 'right'
        }}
      >
        {ac.map((acabado, index) =>
          acabado.opciones.length > 0 ? (
            <AcabadoLista
              key={index}
              acabado={acabado}
              index={ac.length - index}
            />
          ) : null
        )}
        {ac.map((acabado, index) =>
          acabado.opciones.length > 0 ? (
            <AcabadoListaPrecios
              key={index}
              acabado={acabado}
              index={ac.length - index}
            />
          ) : null
        )}
      </View>
      <View
        style={{
          position: 'absolute',
          fontFamily: 'PoppinsBold',
          fontSize: 16,
          left: 0,
          bottom: '9mm'
        }}
      >
        <Text>{estancia.title.toUpperCase()}</Text>
      </View>
      <View
        style={{
          borderTopColor: '#f07204',
          borderTopWidth: '3pt',
          width: '100%',
          position: 'absolute',
          left: 0,
          bottom: '8mm'
        }}
      />
      <View
        style={{
          position: 'absolute',
          bottom: 0,
          right: 0,
          fontFamily: 'PoppinsBold',
          fontSize: 12
        }}
      >
        <Text>{estancia.subtotal}€</Text>
      </View>
    </View>
  )
}

const Pdf = ({ opciones, total, textoIva, srcLogo, textoHeaderPdf }) => {
  const estanciasPage = []

  let estPage = []
  for (let i = 0, iLen = opciones.length; i < iLen; i++) {
    if (i % 2 === 0) {
      estPage = []
    }

    estPage.push(opciones[i])

    if (i % 2 === 1) {
      estanciasPage.push(estPage)
    }
  }

  if (estPage.length === 1) {
    estanciasPage.push(estPage)
  }

  return (
    <Document>
      {estanciasPage.map((estanciaPage, index) => (
        <PageTemplate
          key={index}
          srcLogo={srcLogo}
          textoHeaderPdf={textoHeaderPdf}
        >
          {estanciaPage.length > 0 ? (
            <EstanciaPdf estancia={estanciaPage[0]} position="left" />
          ) : null}
          {estanciaPage.length > 1 ? (
            <EstanciaPdf estancia={estanciaPage[1]} position="right" />
          ) : null}
        </PageTemplate>
      ))}
      <PageTemplate srcLogo={srcLogo}>
        {opciones.map((estancia, index) => (
          <EstanciaLista key={estancia.id} estancia={estancia} index={index} />
        ))}
        <View
          style={{
            position: 'absolute',
            left: paddingMM,
            height: '20mm',
            width: '190mm',
            top: `${opciones.length * 53 + 10}mm`
          }}
        >
          <View
            style={{
              position: 'absolute',
              right: 0,
              top: 0,
              backgroundColor: '#f07204',
              width: '70mm',
              height: '7mm',
              borderTopLeftRadius: '5cm',
              borderTopRightRadius: '5cm',
              borderBottomLeftRadius: '5cm',
              borderBottomRightRadius: '5cm'
            }}
          >
            <Text
              style={{
                fontFamily: 'PoppinsBold',
                fontSize: 14,
                color: 'white',
                textAlign: 'center',
                height: '7mm'
              }}
            >
              Total: {total}€
            </Text>
          </View>
          <View
            style={{
              position: 'absolute',
              right: 0,
              top: '9mm',
              width: '70mm'
            }}
          >
            <Text
              style={{
                fontFamily: 'Poppins',
                fontSize: 12,
                textAlign: 'center'
              }}
            >
              {textoIva}
            </Text>
          </View>
        </View>
      </PageTemplate>
    </Document>
  )
}

export default Pdf
