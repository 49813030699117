import theme from './Theme'

const ButtonJss = ({ className, opciones, opcionesHover }) => {
  const button = {
    borderWidth: '1px',
    cursor: 'pointer',
    textAlign: 'center',
    whiteSpace: 'nowrap',

    '-moz-appearance': 'none',
    '-webkit-appearance': 'none',
    alignItems: 'center',
    border: '1px solid transparent',
    boxShadow: 'none',
    display: 'inline-flex',
    fontSize: '1rem',
    height: '1.5em',
    justifyContent: 'center',
    lineHeight: '1.5',
    paddingBottom: 'calc(0.375em - 1px)',
    paddingTop: 'calc(0.375em - 1px)',
    position: 'relative',
    verticalAlign: 'top',

    '-webkit-touch-callout': 'none',
    '-webkit-user-select': 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    userSelect: 'none',

    textDecoration: 'none',

    borderRadius: '290486px',
    borderTopLeftRadius: '290486px',
    borderTopRightRadius: '290486px',
    borderBottomRightRadius: '290486px',
    borderBottomLeftRadius: '290486px',
    paddingLeft: '1.5em',
    paddingRight: '1.5em',

    backgroundColor: 'transparent',
    borderColor: 'white',
    color: 'white',

    minWidth: '100px',
    '&:hover': {
      backgroundColor: theme.colorPrincipal,
      borderColor: theme.colorPrincipal,
      color: 'white',
      textDecoration: 'none'
    }
  }

  const result = {}
  result[className] = { ...button }
  if (typeof opciones !== 'undefined') {
    result[className] = { ...result[className], ...opciones }
  }
  if (typeof opcionesHover !== 'undefined') {
    result[className] = { ...result[className], ...opcionesHover }
  }

  return result
}

export default ButtonJss
